import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/npl/Icon';

/**
 * `RadioItemCard` is a React component that renders a selectable card with a radio button.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.option - The option object with properties: id and label
 *   - id: {string} The ID of the option.
 *   - label: {string} The label or description of the option.
 * @param {boolean} props.selected - Indicates whether the card is currently selected.
 * @param {Function} props.onSelect - The function to be called when the card is clicked.
 * @param {('lg')} props.size - The size of the card, can only be 'lg' for large size.
 *
 * @example
 * <RadioItemCard
 *   option={{ id: "option-1", label: "Option 1" }}
 *   selected={true}
 *   onSelect={() => {}}
 *   size="lg"
 * />
 *
 * @returns {React.Element} The rendered component.
 */
const RadioItemCard = ({ option, selected, onSelect, size }) => {
  const { label } = option;
  const handleClick = () => {
    onSelect(option);
  };

  return (
    <div
      className={classNames(
        `c-RadioItemCard flex cursor-pointer items-center justify-between space-x-20 rounded-12 border-1 p-16`,
        {
          'h-72': size === 'lg',
          'border-npl-neutral-light-solid-7': selected,
          'border-npl-neutral-light-solid-7 hover:border-npl-neutral-light-solid-12':
            !selected
        }
      )}
      onClick={handleClick}>
      {/* Label */}
      <p className="text-label-md font-medium text-dark-19">{label}</p>
      {/* Radio */}
      {selected ? (
        <div className="h-24 w-24 shrink-0 rounded-full">
          <Icon
            name="check-circle-filled"
            fill="#946800"
            width="24"
            height="24"
          />
        </div>
      ) : (
        <div className="h-24 w-24 shrink-0 rounded-full border-1 border-npl-neutral-light-solid-7"></div> // Unselected radio
      )}
    </div>
  );
};

export default RadioItemCard;
