import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

const InputWithSuggestion = ({
  name,
  suggestions,
  value,
  onChange,
  focusInputOnMount
}) => {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [isVisible, setVisibility] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const inputRef = useRef(null);

  const filterSuggestions = (inputValue) => {
    const filteredSuggestions = suggestions
      .filter((suggestion) =>
        suggestion.toLowerCase().includes(inputValue.toLowerCase())
      )
      .splice(0, 5); // Limit the suggestions array to a maximum of 5

    // Update visibility based on whether there are suggestions
    setFilteredSuggestions(filteredSuggestions);
    setVisibility(filteredSuggestions.length > 0);
  };

  const debouncedFilterSuggestions = debounce((inputValue) => {
    filterSuggestions(inputValue);
  }, 300);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    onChange(inputValue);
    debouncedFilterSuggestions(inputValue);
  };

  const handleSuggestionClick = (suggestion) => {
    onChange(suggestion);
    setVisibility(false);
  };

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start'
    }
  );

  useEffect(() => {
    if (focusInputOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusInputOnMount]);

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
        // placeholder="Type here..."
        ref={(el) => {
          setReferenceElement(el);
          inputRef.current = el;
        }}
        className="w-full rounded-8 border-1 border-npl-neutral-light-solid-7 px-12 py-16 text-body-md text-dark-1b"
      />
      {isVisible && (
        <div
          className="absolute z-over-intercom my-4 max-h-[280px] w-full overflow-y-auto rounded-8 bg-npl-base-white p-4 font-poppins shadow-npl-styles-shadow-02"
          style={styles.popper}
          {...attributes.popper}
          ref={setPopperElement}>
          <ul>
            {filteredSuggestions.map((suggestion) => {
              return (
                <li
                  key={suggestion}
                  className="flex h-40 w-full cursor-pointer flex-row items-center rounded-4 font-poppins text-label-md text-npl-text-icon-on-light-surface-primary hover:bg-npl-neutral-light-solid-3">
                  <button
                    onClick={() => handleSuggestionClick(suggestion)}
                    onMouseDown={(e) => e.preventDefault()}
                    className="h-full w-full px-12 py-8 text-left">
                    {suggestion}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputWithSuggestion;
