import * as yup from 'yup';

import { t } from '@/utility/localization';

import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';

export const createClassSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, 'Title cannot be more than 100 letters!')
    .required(t('title-is-required')),
  description: yup
    .string()
    .max(5000, 'Description cannot be more than 5000 letters!')
    .notRequired(),
  thumbnail: yup.mixed().required('Thumbnail is required'),
  priceType: yup
    .string()
    .oneOf([PRICE_TYPES.FIXED, PRICE_TYPES.FLEXIBLE])
    .required(t('price-type-is-required')),
  minAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  suggestedAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  amount: yup.string().when('priceType', {
    is: PRICE_TYPES.FIXED,
    then: () => yup.string().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  })
});

export const editClassSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, 'Title cannot be more than 100 letters!')
    .required(t('title-is-required')),
  description: yup
    .string()
    .max(5000, 'Description cannot be more than 5000 letters!')
    .notRequired(),
  image: yup
    .mixed()
    .test('imageTest', 'Thumbnail is required', function (value) {
      const thumbnailExists = !!this.parent.thumbnail;
      return thumbnailExists || value;
    }),
  priceType: yup
    .string()
    .oneOf([PRICE_TYPES.FIXED, PRICE_TYPES.FLEXIBLE])
    .required(t('price-type-is-required')),
  minAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  suggestedAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  amount: yup.string().when('priceType', {
    is: PRICE_TYPES.FIXED,
    then: () => yup.string().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  })
});

export const collectionSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, 'Title cannot be more than 100 letters!')
    .required(t('title-is-required')),
  emoji: yup.string().required('Emoji is required'),
  description: yup
    .string()
    .max(1000, 'Description annot be more than 1000 letters!')
    .notRequired()
});

export const folderItemClassSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, 'Title cannot be more than 60 letters!')
    .required(t('title-is-required')),
  description: yup
    .string()
    .max(1000, 'Description cannot be more than 400 letters!')
    .notRequired()
});
